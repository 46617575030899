// extracted by mini-css-extract-plugin
export var button = "PlasmicFooter-module--button--3cfDF";
export var freeBox___1YaFh = "PlasmicFooter-module--freeBox___1YaFh--nlPQN";
export var freeBox___6JzI = "PlasmicFooter-module--freeBox___6JzI--1Kocp";
export var freeBox__fBiEc = "PlasmicFooter-module--freeBox__fBiEc--nFuYe";
export var freeBox__hV6DS = "PlasmicFooter-module--freeBox__hV6DS--85O3S";
export var freeBox__hZnA2 = "PlasmicFooter-module--freeBox__hZnA2--zW7Lj";
export var freeBox__j3HNg = "PlasmicFooter-module--freeBox__j3HNg--m+PLd";
export var freeBox__lcmHk = "PlasmicFooter-module--freeBox__lcmHk--9jKny";
export var freeBox__mIdDt = "PlasmicFooter-module--freeBox__mIdDt--jBlcw";
export var freeBox__pnYfl = "PlasmicFooter-module--freeBox__pnYfl--HXV27";
export var freeBox__rgOeZ = "PlasmicFooter-module--freeBox__rgOeZ--PaNFB";
export var freeBox__uwhaJ = "PlasmicFooter-module--freeBox__uwhaJ--sB+WA";
export var freeBox__w6WQi = "PlasmicFooter-module--freeBox__w6WQi--KBCqr";
export var freeBox__zNpL1 = "PlasmicFooter-module--freeBox__zNpL1--A7ZcP";
export var iconLink__cUgd = "PlasmicFooter-module--iconLink__cUgd--mm1AQ";
export var iconLink__g3N7V = "PlasmicFooter-module--iconLink__g3N7V---A8-5";
export var iconLink__gCmp = "PlasmicFooter-module--iconLink__gCmp--BqWGe";
export var iconLink__xFbk = "PlasmicFooter-module--iconLink__xFbk--kX8eE";
export var link___0TiF = "PlasmicFooter-module--link___0TiF--20grZ";
export var link___62ZpH = "PlasmicFooter-module--link___62ZpH--IUPUB";
export var link__bo1Zc = "PlasmicFooter-module--link__bo1Zc--sIr+S";
export var link__csUOb = "PlasmicFooter-module--link__csUOb--kOlvI";
export var link__evAeS = "PlasmicFooter-module--link__evAeS--Rrzim";
export var link__iysS = "PlasmicFooter-module--link__iysS--2AW-z";
export var link__lHalh = "PlasmicFooter-module--link__lHalh--tAA6W";
export var link__mSsPq = "PlasmicFooter-module--link__mSsPq--JGa+U";
export var link__nFseK = "PlasmicFooter-module--link__nFseK--IeqBJ";
export var link__ohGab = "PlasmicFooter-module--link__ohGab--linvS";
export var link__pOmZ = "PlasmicFooter-module--link__pOmZ--Sx4Lb";
export var link__piSo = "PlasmicFooter-module--link__piSo--UUVFg";
export var link__s1NkA = "PlasmicFooter-module--link__s1NkA--DZCAz";
export var link__uPxrV = "PlasmicFooter-module--link__uPxrV--yhtA8";
export var link__vBr8 = "PlasmicFooter-module--link__vBr8--SSXD+";
export var link__wjN9M = "PlasmicFooter-module--link__wjN9M--WO1mw";
export var link__x4Ox6 = "PlasmicFooter-module--link__x4Ox6--c5fIY";
export var link__yQaek = "PlasmicFooter-module--link__yQaek--97TxJ";
export var link__zX8Nt = "PlasmicFooter-module--link__zX8Nt--0NBBy";
export var root = "PlasmicFooter-module--root--WcEx6";
export var svg__a16Wj = "PlasmicFooter-module--svg__a16Wj--4PNJU";
export var svg__bWuPy = "PlasmicFooter-module--svg__bWuPy--VjEtE";
export var svg__crHV = "PlasmicFooter-module--svg__crHV--6vDTM";
export var svg__eDjsu = "PlasmicFooter-module--svg__eDjsu--VjLTO";
export var svg__vkt89 = "PlasmicFooter-module--svg__vkt89--0evN5";
export var svg__wDnzh = "PlasmicFooter-module--svg__wDnzh--dKSQZ";
export var svg__x3EV = "PlasmicFooter-module--svg__x3EV--0NWMj";
export var svg__x5Ocx = "PlasmicFooter-module--svg__x5Ocx--pri1C";
export var textInput = "PlasmicFooter-module--textInput--UnyrH";
export var text__c3KT7 = "PlasmicFooter-module--text__c3KT7--tM6Xe";
export var text__dLGX = "PlasmicFooter-module--text__dLGX--5Jr-t";
export var text__ikEiu = "PlasmicFooter-module--text__ikEiu--3UOov";
export var text__pcHkJ = "PlasmicFooter-module--text__pcHkJ--3LSy8";
export var text__psjk6 = "PlasmicFooter-module--text__psjk6--k-fUR";
export var text__tqoWg = "PlasmicFooter-module--text__tqoWg--nqeCP";
export var text__x3479 = "PlasmicFooter-module--text__x3479--omfUw";