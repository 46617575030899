// extracted by mini-css-extract-plugin
export var content = "PlasmicSection-module--content--OT3jB";
export var contentContainer = "PlasmicSection-module--contentContainer--1nAGb";
export var contentContainerhasSubtitle = "PlasmicSection-module--contentContainerhasSubtitle--ssy6N";
export var contentContainerhasSubtitle_hasTitle = "PlasmicSection-module--contentContainerhasSubtitle_hasTitle--xrNKQ";
export var contentContainerhasTitle = "PlasmicSection-module--contentContainerhasTitle--1cn8z";
export var root = "PlasmicSection-module--root--x4DBA";
export var rootcolor_dark = "PlasmicSection-module--rootcolor_dark--tYjXj";
export var rootcolor_halfDark = "PlasmicSection-module--rootcolor_halfDark--hoUYe";
export var slotTargetSubtitle = "PlasmicSection-module--slotTargetSubtitle--yCViG";
export var slotTargetTitle = "PlasmicSection-module--slotTargetTitle--oDxjf";
export var subtitleContainer = "PlasmicSection-module--subtitleContainer--miyY3";
export var subtitleContainerhasSubtitle = "PlasmicSection-module--subtitleContainerhasSubtitle--axOU8";
export var titleContainer = "PlasmicSection-module--titleContainer--sjqNz";
export var titleContainerhasTitle = "PlasmicSection-module--titleContainerhasTitle--yIYYY";