// extracted by mini-css-extract-plugin
export var a = "plasmic_simple_light_landing_page-module--a--iI8KS";
export var address = "plasmic_simple_light_landing_page-module--address--nCsSg";
export var all = "plasmic_simple_light_landing_page-module--all--7tVqh";
export var blockquote = "plasmic_simple_light_landing_page-module--blockquote--Sd8XS";
export var button = "plasmic_simple_light_landing_page-module--button--xZ7Dc";
export var code = "plasmic_simple_light_landing_page-module--code--TIfk9";
export var h1 = "plasmic_simple_light_landing_page-module--h1--Glb5P";
export var h2 = "plasmic_simple_light_landing_page-module--h2--rWJao";
export var h3 = "plasmic_simple_light_landing_page-module--h3--Zv83D";
export var h4 = "plasmic_simple_light_landing_page-module--h4--dfA37";
export var h5 = "plasmic_simple_light_landing_page-module--h5--CWX7+";
export var h6 = "plasmic_simple_light_landing_page-module--h6--Hq8nN";
export var img = "plasmic_simple_light_landing_page-module--img--etWgx";
export var input = "plasmic_simple_light_landing_page-module--input--XsICl";
export var li = "plasmic_simple_light_landing_page-module--li--7o0vp";
export var ol = "plasmic_simple_light_landing_page-module--ol--e-fwd";
export var p = "plasmic_simple_light_landing_page-module--p--GnVAT";
export var plasmic_default__blockquote = "plasmic_simple_light_landing_page-module--plasmic_default__blockquote--XeSRw";
export var plasmic_default__code = "plasmic_simple_light_landing_page-module--plasmic_default__code--ryBK1";
export var plasmic_default__component_wrapper = "plasmic_simple_light_landing_page-module--plasmic_default__component_wrapper--nn7Dw";
export var plasmic_default__h1 = "plasmic_simple_light_landing_page-module--plasmic_default__h1--2Cl73";
export var plasmic_default__h2 = "plasmic_simple_light_landing_page-module--plasmic_default__h2--SWpQx";
export var plasmic_default__h3 = "plasmic_simple_light_landing_page-module--plasmic_default__h3--DLo0y";
export var plasmic_default__h4 = "plasmic_simple_light_landing_page-module--plasmic_default__h4--tr0K4";
export var plasmic_default__h5 = "plasmic_simple_light_landing_page-module--plasmic_default__h5--PNnAc";
export var plasmic_default__h6 = "plasmic_simple_light_landing_page-module--plasmic_default__h6--2SG3Z";
export var plasmic_default__inline = "plasmic_simple_light_landing_page-module--plasmic_default__inline--LOdHg";
export var plasmic_default__ol = "plasmic_simple_light_landing_page-module--plasmic_default__ol--jw7Sc";
export var plasmic_default__pre = "plasmic_simple_light_landing_page-module--plasmic_default__pre--fd6fF";
export var plasmic_default__ul = "plasmic_simple_light_landing_page-module--plasmic_default__ul---Z77t";
export var plasmic_default_styles = "plasmic_simple_light_landing_page-module--plasmic_default_styles--GuwdP";
export var plasmic_page_wrapper = "plasmic_simple_light_landing_page-module--plasmic_page_wrapper--wq0WO";
export var plasmic_tokens = "plasmic_simple_light_landing_page-module--plasmic_tokens--kVMnN";
export var pre = "plasmic_simple_light_landing_page-module--pre--mBhYH";
export var root_reset = "plasmic_simple_light_landing_page-module--root_reset--kOho-";
export var select = "plasmic_simple_light_landing_page-module--select--e3t8D";
export var span = "plasmic_simple_light_landing_page-module--span--y9C03";
export var textarea = "plasmic_simple_light_landing_page-module--textarea--3x5kh";
export var ul = "plasmic_simple_light_landing_page-module--ul--C5L6Q";