// extracted by mini-css-extract-plugin
export var a = "plasmic_library_plasmic_color_type-module--a--rvHBl";
export var address = "plasmic_library_plasmic_color_type-module--address--R1XFi";
export var all = "plasmic_library_plasmic_color_type-module--all--cXsLC";
export var button = "plasmic_library_plasmic_color_type-module--button--VMboR";
export var code = "plasmic_library_plasmic_color_type-module--code--U-y7w";
export var h1 = "plasmic_library_plasmic_color_type-module--h1--OgUTn";
export var h2 = "plasmic_library_plasmic_color_type-module--h2--LM84L";
export var h3 = "plasmic_library_plasmic_color_type-module--h3--BwHmL";
export var h4 = "plasmic_library_plasmic_color_type-module--h4--Z8aEZ";
export var h5 = "plasmic_library_plasmic_color_type-module--h5--qkras";
export var h6 = "plasmic_library_plasmic_color_type-module--h6--qeHNX";
export var img = "plasmic_library_plasmic_color_type-module--img--MZ4r1";
export var input = "plasmic_library_plasmic_color_type-module--input--KnKCe";
export var li = "plasmic_library_plasmic_color_type-module--li--VZzZf";
export var ol = "plasmic_library_plasmic_color_type-module--ol--yJeWE";
export var p = "plasmic_library_plasmic_color_type-module--p--+ul-w";
export var plasmic_default__component_wrapper = "plasmic_library_plasmic_color_type-module--plasmic_default__component_wrapper--dmdRV";
export var plasmic_default__inline = "plasmic_library_plasmic_color_type-module--plasmic_default__inline--BzGQ6";
export var plasmic_default_styles = "plasmic_library_plasmic_color_type-module--plasmic_default_styles--BOilC";
export var plasmic_page_wrapper = "plasmic_library_plasmic_color_type-module--plasmic_page_wrapper--hUOUO";
export var plasmic_tokens = "plasmic_library_plasmic_color_type-module--plasmic_tokens--X2-Hn";
export var pre = "plasmic_library_plasmic_color_type-module--pre--3lfoH";
export var root_reset = "plasmic_library_plasmic_color_type-module--root_reset--VcNgB";
export var select = "plasmic_library_plasmic_color_type-module--select--xjsIN";
export var span = "plasmic_library_plasmic_color_type-module--span--oTQI5";
export var textarea = "plasmic_library_plasmic_color_type-module--textarea--CXp7N";
export var ul = "plasmic_library_plasmic_color_type-module--ul--Bdvon";